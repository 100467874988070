import { getOrdersList } from "./actions";
import {
  GET_ORDERS_LIST_FULFILLED,
  GET_ORDERS_LIST_PENDING,
  GET_ORDERS_LIST_REJECTED,
} from "./constans";

interface IState {
  orders: any[];
  pending: boolean;
  totalCount: number;
}

const initialState = {
  orders: [],
  pending: true,
  totalCount: 0,
};

export function ordersReducer(state: IState = initialState, action: any) {
  switch (action.type) {
    case GET_ORDERS_LIST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_ORDERS_LIST_FULFILLED:
      return {
        orders: [...action.payload.orders],
        totalCount: action.payload.totalCount,
        pending: false,
      };
    case GET_ORDERS_LIST_REJECTED:
      return {
        ...state,
        pending: false,
      };
    default:
      break;
  }
  return state;
}
