import RequestServices from "../helpers/requestServices";

import * as constants from "./constans";

const api = new RequestServices({});

export function getAccountsData(dispatch: any) {
  // console.log(data)
  return async () => {
    const { data } = await api.getAccountsData();
    const agentsData = await api.getSubAccouts()
    dispatch({
        type: constants.GET_ACCOUNTS_DATA,
        payload: {
          fullName: data.data.account.contact_person,
          photo: data.data.account.photo,
          agentsArray: [data.data.account, ...agentsData.data.data.items]
        }
    });
  };
}
