import { combineReducers } from "redux";
import { History } from "history";
import { connectRouter } from "connected-react-router";
import { registrationReducer } from "./Authorization/Registration/reducer";
import famReducer from "./Fam/src/rootReducer";
import { AppReducer } from "./App/reducer";
import { ordersReducer } from "./Properties/reducer";
import { MainReducer } from "./Main/reducer";

const rootReducer = (history: History) =>
  combineReducers({
    profileProperty: registrationReducer,
    app: AppReducer,
    router: connectRouter(history),
    orders: ordersReducer,
    main: MainReducer,
    // fam: famReducer
  } as object);

export default rootReducer;
