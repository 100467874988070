import axios from "axios";
const qs = require("qs");

const postData = {
  client_id: "FloorplaOnline Rest API",
  client_secret: process.env.REACT_APP_NOT_CLIENT_SECRET,
  grant_type: "client_credentials",
};

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
};

const apiUrl = process.env.REACT_APP_API_URL;

const AUTOHORIZE = "/api/authorize";
const VALIDATION = "/api/v1/actions/validation";
const REFRESH_TOKEN = "/api/refresh_token";
const ACCOUNTS = "/api/accounts";
const ORDERS = "/api/v1/orders";
const FORGOT_PASSWORD = "/api/v1/actions/forgot-password";
const HOTSPOTS = "/hotspots";
const LISTING_STATUS = "/api/v1/helpers/listing-status";
const PARENT = '/api/v1/actions/parent'

const AUTH_OLD = "/api/v1/actions/auth/login";
const CHECK_MLS ="/api/checkmls"

const STATES = "/api/v1/helpers/states?country_id=1";

const accountsStorage = window.localStorage;
let token = accountsStorage.getItem("token");
let refreshToken = accountsStorage.getItem("refreshToken");
let uuid = accountsStorage.getItem("uuid");

export default class RequestServies {
  client: any;
  token: string | null;
  refreshToken: string | null;
  refreshRequest: any;
  constructor(options: any) {
    this.client = axios.create();
    this.token = token;
    this.refreshToken = refreshToken;
    this.refreshRequest = null;

    this.client.interceptors.request.use(
      (config: any) => {
        if (!token) {
          return config;
        }

        const newConfig = {
          headers: {},
          ...config,
        };

        newConfig.headers.Authorization = `Bearer ${token}`;
        return newConfig;
      },
      (e: any) => Promise.reject(e)
    );

    this.client.interceptors.response.use(
      (r: any) => r,
      async (error: {
        response: { status: number };
        config: { retry: any };
      }) => {
        if (error.response.status === 401) {
          window.location.href = apiUrl || window.location.origin;
        }
        if (
          !this.refreshToken ||
          error.response.status !== 401 ||
          error.config.retry
        ) {
          throw error;
        }
        window.location.href = apiUrl || window.location.origin;
        // if (!this.refreshRequest) {
        //   this.refreshRequest = this.client.post(`${apiUrl}${REFRESH_TOKEN}`, {
        //     refresh_token: this.refreshToken,
        //     grant_type: "refresh_token",
        //   });
        // }

        // const { data } = await this.refreshRequest;
        // this.token = data.access_token;
        // this.refreshToken = data.refresh_token;
        // accountsStorage.setItem("token", data.access_token);
        // accountsStorage.setItem("refreshToken", data.refresh_token);
        // const newRequest = {
        //   ...error.config,
        //   retry: true,
        // };
        // return this.client(newRequest);
      }
    );
  }

  async loginApp() {
    const { data } = await this.client.post(
      `${apiUrl}${AUTOHORIZE}`,
      qs.stringify(postData),
      headers
    );
    token = data.access_token;
    // accountsStorage.setItem("token", data.access_token);
  }

  async loginUser(login: string, password: string) {
    try {
      const loginData = await this.client.post(
        `${apiUrl}${AUTH_OLD}`,
        {
          username: login,
          password,
        },
        {
          withCredentials: true,
        }
      );
      const { data } = await this.client.post(
        `${apiUrl}${AUTOHORIZE}`,
        qs.stringify({
          ...postData,
          grant_type: "password",
          username: login,
          password,
        }),
        { headers, withCredentials: true }
      );

      // accountsStorage.setItem("token", data.access_token);
      // accountsStorage.setItem("refreshToken", data.refresh_token);
      // accountsStorage.setItem("uuid", data.uuid);

      token = data.access_token;
      refreshToken = data.refresh_token;
      uuid = data.uuid;
      this.getAccountsData();
      return loginData;
    } catch (error) {
      return {
        type: "error",
        error: error,
      };
    }
  }

  forgotPassword(email: string, username :string) {
    return this.client.get(`${apiUrl}${FORGOT_PASSWORD}`, {
      params: {
        emailOrLogin: email,
        ...(username && {username})
      },
    });
  }

  resetPassword(password: string, confirmPassword: string) {
    return this.client.patch(
      `${apiUrl}/api/v1/actions/reset-password/${
        window.location.pathname.split("/")[3]
      }`,
      {
        password: password,
        confirm_password: confirmPassword,
      }
    );
  }

  getAccountsData() {
    return this.client.get(`${apiUrl}/api/v1/actions/current`, {
      withCredentials: true,
    });
  }

  getOrdersList(params?: any, filter?: any, searchValue?: string) {
    const search = searchValue ? {
      q: searchValue
    } : {

    }
    return this.client.get(
      `${apiUrl}${ORDERS}`,
      {
        withCredentials: true,
        params: {
          ...params,
          ...filter,
          ...search
        },
      }
    );
  }

  getOrder(uuid: string) {
    return this.client.get(
      `${apiUrl}${ORDERS}/${uuid}?relations=orderType,states,status`,
      {
        withCredentials: true,
      }
    );
  }

  registrationAccounts(data: any) {
    return this.client.post(`${apiUrl}${ACCOUNTS}`, data, {
      withCredentials: true,
    });
  }

  getStates() {
    return this.client.get(`${apiUrl}${STATES}`);
  }

  uploadFile(type: string, uid: string, data: any) {
    if (!data) {
      return false;
    }
    return this.client.post(`${apiUrl}${ACCOUNTS}/${uid}/${type}`, data, {
      withCredentials: true,
    });
  }

  validateUnique(type: string, value: string) {
    return this.client.get(`${apiUrl}${VALIDATION}`, {
      params: {
        [type]: value,
      },
    });
  }

  getHotspots(uid: string) {
    return this.client.get(
      `${apiUrl}${ORDERS}/${uid}${HOTSPOTS}${window.location.search}`,
      {
        withCredentials: true,
      }
    );
  }

  saveHotspots(uid: string, data: any) {
    return this.client.patch(
      `${apiUrl}${ORDERS}/${uid}${HOTSPOTS}${window.location.search}`,
      { ...data },
      {
        withCredentials: true,
      }
    );
  }

  getLogo() {
    const getQueryVariable = (variable: string) => {
      const query = window.location.search.substring(1);
      const vars = query.split("&");
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        if (decodeURIComponent(pair[0]) == variable) {
          return decodeURIComponent(pair[1]);
        }
      }
    };
    if(!getQueryVariable("puuid")) {
      return false
    }
    try {
      return this.client.get(
        `${apiUrl}/api/v1/actions/parent/${getQueryVariable("puuid")}`
      );
    } catch (error) {
      return false;
    }
  }

  getListingStatus() {
    return this.client.get(`${apiUrl}${LISTING_STATUS}`);
  }

  changeOrderData(key: string ,uid: string, value: number) {
    try {
      this.client.patch(
        `${apiUrl}${ORDERS}/${uid}`,
        {
          [key]: value,
        },
        {
          withCredentials: true,
        }
      );
    } catch (error) {}
  }

  changeOrderMultipleData(data: any ,uid: string) {
    try {
      this.client.patch(
        `${apiUrl}${ORDERS}/${uid}`,data,
        {
          withCredentials: true,
        }
      );
    } catch (error) {}
  }

  searchOrders(value: string, offset: number) {
    return this.client.get(`${apiUrl}${ORDERS}?q=${value}&relations=orderType,states,listingStatus,member&offset=${offset}`, {
      withCredentials: true
    })
  }

  getSubAccouts() {
    return this.client.get(`${apiUrl}${ACCOUNTS}?limit=10000`, {
      withCredentials: true
    })
  }

  checkMls(memberId: string, mlsId: string) {
    return this.client.post(`${apiUrl}${CHECK_MLS}/${memberId}`, qs.stringify({
      mls_id: mlsId
    }), {
      withCredentials: true
    })
  }

  getPlanCorection(uuid: string, puid?: string) {
    return this.client.get(`${apiUrl}${ORDERS}/${uuid}/corrections${puid ? puid : '' }`, {
      withCredentials: true
    })
  }

  uploadPlanCorection(uuid: string, formData: any) {
    return this.client.post(`${apiUrl}${ORDERS}/${uuid}/corrections`, formData, {
      withCredentials: true
    })
  }

  getWindermereUser(puuid: string) {
    return this.client.get(`${apiUrl}${PARENT}/${puuid}/windermere`, {
      withCredentials: true
    })
  }

}
