import {connect} from 'react-redux';
import AppComponent from './AppComponent'

import * as actions from './actions'

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getAccountsData: actions.getAccountsData(dispatch)
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(AppComponent)
