import { getAccountsData } from "./actions";
import { GET_ACCOUNTS_DATA } from "./constans";

interface IState {
    fullName: string;
    photo: string;
    agentsArray: any
}

const initialState = {
    fullName: '',
    photo: '',
    agentsArray: []
}


export function AppReducer(state: IState = initialState, action: any) {
    switch (action.type) {
        case GET_ACCOUNTS_DATA:
            return {
                fullName: action.payload.fullName,
                photo: action.payload.photo,
                agentsArray: action.payload.agentsArray
            }
        default:
            break;
    }
    return state
}
