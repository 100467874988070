import React, { Component, Suspense } from "react";
import { History } from "history";
import { Spin } from "antd";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch, Router } from "react-router";
import "antd/dist/antd.css";
import styles from "./App.module.scss";
import "./global.scss";

import RequestServies from '../helpers/requestServices'

const api = new RequestServies({})
const SignInComponent = React.lazy(() =>
  import("../Authorization/SignIn/SignInComponent")
);

const RegistrationComponent = React.lazy(() =>
  import("../Authorization/Registration/RegistrationContainer")
);

const MainComponent = React.lazy(() => import("../Main/MainComponent"));

const ForgotPasswordComponent = React.lazy(() =>
  import("../Authorization/SignIn/ForgotPassword/ForgotPasswordComponent")
);

const ResetPasswordComponent = React.lazy(() =>
  import("../Authorization/SignIn/ForgotPassword/ResetPasswordComponent")
);

const HotspotsComponent = React.lazy(() => import("../Hotspots/HotspotsComponent"))

const CorrectionComponent = React.lazy(() => import("../FloorPlanCorrection/CorrectionComponent"))

interface IAppProps {
  history: History;
  getAccountsData: any
}

class App extends Component<IAppProps, any> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      token: "",
    };
  }
  componentDidMount = () => {
    api.loginApp();
    // this.props.getAccountsData();
    const list: any = document.head.children;
    for (let item of list) {
      if (item.tagName === "LINK") {
        if (item.href.includes("NewOrder")) {
          item.disabled = true;
        }
      }
    }
  };

  public render() {
    const { history } = this.props;
    return (
      <div className={styles.App}>
        <ConnectedRouter history={history}>
          <Router history={history}>
            <Suspense
              fallback={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <Spin />
                </div>
              }
            >
              {/* <Route path="/" exact component={MainComponent} />
               */}
              {/* <MainComponent /> */}
              <Switch>
                {/* <Route path="/property" component={MainComponent} /> */}
                <Route path="/signin" component={SignInComponent} />
                <Route path="/registration" component={RegistrationComponent} />
                <Route
                  path="/forgot"
                  exact
                  component={ForgotPasswordComponent}
                />
                <Route
                  path="/forgot/:code"
                  component={ResetPasswordComponent}
                />
                <Route
                  path="/hotspots/:id"
                  component={HotspotsComponent}
                />
                <Route
                  path="/plan-correction/:id"
                  component={CorrectionComponent}
                />
              </Switch>
            </Suspense>
          </Router>
        </ConnectedRouter>
      </div>
    );
  }
}

export default App;
